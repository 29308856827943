/* eslint-disable import/no-anonymous-default-export */
export default {
  items: [
    {
      title: true,
      name: "FLYMYA-V4",
      wrapper: {
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Reservations",
      url: "/reservations",
      icon: "cui-list",
    },
    {
      name: "Operation Transactions",
      url: "/operation-transactions",
      icon: "cui-dollar",
    },
    {
      name: "Products",
      url: "/products",
      icon: "cil-basket",
    },
    {
      name: "Tasks",
      url: "/tasks",
      icon: "cil-check",
    },
    {
      name: "Arrangers",
      url: "/arrangers",
      icon: "cui-people",
    },
    // {
    //   name: "Passengers",
    //   url: "/passengers",
    //   icon: "cui-people"
    // },
    {
      name: "Company",
      url: "/company",
      icon: "cil-factory",
    },
    {
      name: "Company Accounts",
      url: "/company-accounts",
      icon: "cil-chart",
    },
    {
      name: "Task Handover",
      url: "/task-handover",
      icon: "cil-chart",
    },
    {
      name: "Currency Calculator",
      url: "/currency-calculator",
      icon: "cui-dollar",
    },
    {
      name: "Flight Cancellation",
      url: "/flightCancellation",
      icon: "icon-settings",
    },
    {
      name: "Translations",
      icon: "cil-language",
      children: [
        {
          name: "Translation List",
          url: "/translation-list",
          icon: "cil-language",
        },
        {
          name: "Translation Model",
          url: "/translation-model",
          icon: "cil-language",
        },
        {
          name: "Translation Language",
          url: "/translation-language",
          icon: "cil-language",
        },
      ],
    },
    {
      name: "Commercial",
      icon: "icon-settings",
      children: [
        {
          name: "Campaigns",
          url: "/commercial/campaigns",
          icon: "cil-braille",
        },
        {
          name: "Promo Import Logs",
          url: "/commercial/promo-import-logs",
          icon: "icon-settings",
        },
        {
          name: "Promotions",
          url: "/commercial/promotions",
          icon: "cil-library",
        },
        {
          name: "Loyalty Program Milestones",
          url: "/commercial/loyalty-program-milestones",
          icon: "cil-flag-alt",
        },
        // {
        //   name: "Tour Packages",
        //   url: "/commercial/tour-packages",
        //   icon: "cil-library",
        // },
        {
          name: "Push Notification",
          url: "/commercial/push-notification",
          icon: "cui-list",
        },
        {
          name: "Loyalty Tier",
          url: "/commercial/loyalty-tier",
          icon: "cui-dollar",
        },
        {
          name: "Incentives",
          icon: "icon-settings",
          children: [
            {
              name: "All incentives",
              url: "/incentives",
              icon: "cil-braille",
            },
            {
              name: "Promo Codes",
              url: "/incentives/promo-code",
              icon: "cil-casino",
            },
            {
              name: "CashBacks",
              url: "/incentives/cashback",
              icon: "cil-casino",
            },
            {
              name: "Referrals",
              url: "/incentives/referrals",
              icon: "cui-list",
            },
            {
              name: "Arranger Referrals",
              url: "/incentives/arranger-referrals",
              icon: "cui-list",
            },
            {
              name: "Company Referrals",
              url: "/incentives/company-referrals",
              icon: "cui-list",
            },
            {
              name: "Coupons",
              url: "/incentives/coupons",
              icon: "cil-cash",
            },
            {
              name: "Arranger Coupons",
              url: "/incentives/arranger_coupons",
              icon: "cil-cash",
            },
          ],
        },
        {
          name: "Email Banner Manager",
          icon: "icon-settings",
          children: [
            {
              name: "Header Banner",
              url: "/email-banner-manager/header-banner",
              icon: "cil-braille",
            },
            {
              name: "Footer Banner",
              url: "/email-banner-manager/footer-banner",
              icon: "cil-braille",
            },
            {
              name: "Default Banner",
              url: "/email-banner-manager/default-banner",
              icon: "cil-braille",
            },
          ],
        },
        {
          name: "Countdown",
          url: "/commercial/countdown",
          icon: "icon-settings",
        },
        {
          name: "SMS Blasting Setup",
          url: "/commercial/campaigns/smsblasting",
          icon: "icon-settings",
        },
        {
          name: "SMS Blasting Log",
          url: "/commercial/smsblastinglog",
          icon: "icon-settings",
        },
        {
          name: "Merchandises",
          url: "/commercial/merchandises",
          icon: "cui-dollar",
        },
        {
          name: "Merchandise Order List",
          url: "/commercial/merchandise-order-list",
          icon: "cil-list",
        },
      ],
    },
    {
      name: "HR",
      icon: "icon-settings",
      children: [
        {
          name: "Department",
          url: "/hr/department",
          icon: "cui-people",
        },
        {
          name: "Employees",
          url: "/hr/employee",
          icon: "cui-people",
        },
      ],
    },
    {
      name: "Setup",
      icon: "icon-settings",
      children: [
        {
          name: "Airports",
          url: "/setup/airport",
          icon: "cui-list",
        },
        {
          name: "Sales Channel",
          url: "/setup/saleschannel",
          icon: "cil-list",
        },
        {
          name: "Sales Category",
          url: "/setup/salescategory",
          icon: "cil-list",
        },
        {
          name: "Arranger Merge",
          url: "/setup/arranger-merge",
          icon: "cui-people",
          children: [
            {
              name: "Arranger Email Merge",
              url: "/setup/arranger-merge/email",
              icon: "cil-list",
            },
            {
              name: "Arranger Phone Merge",
              url: "/setup/arranger-merge/phone",
              icon: "cil-list",
            },
            {
              name: "Arranger ID Merge",
              url: "/setup/arranger-merge/id",
              icon: "cil-list",
            },
          ],
        },
        {
          name: "Airlines",
          url: "/setup/airline",
          icon: "cui-list",
        },
        {
          name: "Automated Message Template",
          url: "/setup/automated_message_template",
          icon: "cui-phone",
        },
        {
          name: "Blacklist",
          url: "/setup/blacklist",
          icon: "cil-report-slash",
        },

        {
          name: "Bus Class Type",
          url: "/setup/bus-class-type",
          icon: "cui-list",
        },
        {
          name: "Class Type $SF",
          icon: "icon-settings",
          children: [
            {
              name: "Class Breakdown",
              url: "/setup/class-breakdown",
              icon: "cil-list",
            },
            {
              name: "Class Breakdown By Sector",
              url: "/setup/class-breakdown-sector",
              icon: "cil-list",
            },
          ],
        },
        {
          name: "Clients",
          url: "/setup/client",
          icon: "cui-list",
        },
        {
          name: "Crawled Routes",
          url: "/setup/crawledRoutes",
          icon: "icon-settings",
        },
        {
          name: "Employees",
          url: "/hr/employee",
          icon: "cui-list",
        },
        // fare package import
        {
          name: "Fare Package Info",
          icon: "icon-settings",
          children: [
            {
              name: "Fare Package Import",
              icon: "icon-settings",
              url: "/setup/fare_package_import",
            },
            {
              name: "Fare Package Sector Import",
              icon: "icon-settings",
              url: "/setup/fare_package_sector_import",
            },
            {
              name: "Flymya Fare Policy",
              icon: "icon-settings",
              url: "/setup/flymya_fare_policy",
            },
          ],
        },
        {
          name: "Feedback",
          url: "/setup/feedback",
          icon: "cil-smile-plus",
        },
        {
          name: "Hotel Manager",
          url: "/setup/hotel-manager",
          icon: "icon-settings",
        },

        {
          name: "Landmarks",
          url: "/setup/landmark",
          icon: "cil-map",
        },
        // {
        //   name: "Loyalty",
        //   url: "/setup/loyalty-tier",
        //   icon: "cui-dollar",
        // },
        {
          name: "Logins",
          url: "/setup/logins",
          icon: "cui-user",
        },
        {
          name: "Mobile App Version",
          url: "/setup/mobile-app-versions",
          icon: "cui-phone",
        },

        //offline products
        {
          name: "Offline Products",
          icon: "icon-settings",
          children: [
            {
              name: "Flight",
              url: "/offline-products/flight",
              icon: "cil-list",
            },
            {
              name: "Flight Validation",
              url: "/offline-products/flight-validation",
              icon: "cil-list",
            },
            {
              name: "Bus",
              url: "/offline-products/bus",
              icon: "cil-list",
            },
            {
              name: "Bus Validation",
              url: "/offline-products/bus-validation",
              icon: "cil-list",
            },
            {
              name: "Offline Product Types",
              url: "/offline-products/offline-product-types",
              icon: "cil-list",
            },
            {
              name: "Balloon Season Ending",
              url: "/offline-products/balloon-season-ending",
              icon: "icon-settings",
            },
          ],
        },
        {
          name: "Operator $MAR",
          icon: "icon-settings",
          children: [
            {
              name: "Flight $MAR",
              url: "/setup/flight-mar-calc",
              icon: "cil-list",
            },
            {
              name: "Flight $MAR By Sector",
              url: "/setup/flight-mar-calc-sector",
              icon: "cil-list",
            },
          ],
        },
        {
          name: "Operators",
          url: "/setup/operators",
          icon: "cui-list",
        },
        {
          name: "Payment Method",
          url: "/setup/payment-method",
          icon: "cui-dollar",
        },
        {
          name: "Permissions",
          url: "/setup/permissions",
          icon: "cui-lock-locked",
        },
        {
          name: "Product Type",
          url: "/setup/product-type",
          icon: "cui-list",
        },
        {
          name: "Roles",
          url: "/setup/roles",
          icon: "cui-people",
        },
        {
          name: "ShortLink QR",
          url: "/setup/shortlink-qr",
          icon: "cui-list",
        },
        {
          name: "Google Page Speed Insight",
          url: "/setup/google-page-speed-insight",
          icon: "cib-google",
        },
      ],
    },
    {
      name: "Finance (WIP)",
      icon: "cil-bank",
      children: [
        {
          name: "Bank Accounts",
          url: "/finance/bank-accounts",
          icon: "cil-tablet",
        },
        {
          name: "Chart Of Accounts",
          url: "/finance/chart-of-accounts",
          icon: "cil-tablet",
        },
        {
          name: "Bank Transactions",
          url: "/finance/bank-transactions",
          icon: "cui-graph",
        },
        {
          name: "Financial Transactions",
          url: "/finance/financial-transactions",
          icon: "cil-calculator",
        },
        {
          name: "Financial Matching Tool",
          url: "/finance/financial-matching-tool",
          icon: "cil-lan",
        },
        {
          name: "Valid Date",
          url: "/finance/valid-date",
          icon: "cil-lan",
        },
        {
          name: "Currency",
          url: "/currency",
          icon: "cui-dollar",
        },
        {
          name: "Export Progress",
          url: "/finance/export-progress",
          icon: "cil-tablet",
        },
      ],
    },
  ],
};
